/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { CdnImage } from '@buzzfeed/react-components';
import { isBfExternalLink } from '../../../utils/isBfExternalLink';
import FeedCard from '../../FeedCard';
import Sponsor from '../../Sponsor';
import ImagePlaceholder from '../../../static/img/image-placeholder.svg';
import styles from './momentOfTheDay.module.scss';

export const MomentOfTheDay = ({ className = '', data = {} } = {}) => {
  const content = data.items?.[0]?.content;
  const sponsorship = data.items?.[0]?.sponsorship;
  const sponsor = sponsorship?.sponsor;

  if (!content) return null;

  const { url, headline, images, content_reactions: contentReactions = {} } = content;
  const forceNewBrowserTab = isBfExternalLink(url);

  const baseTrackingData = {
    ...data.items?.[0]?.content.trackingData,
    unit_name: 'splash',
    subunit_type: 'component',
    subunit_name: 'moment_of_the_day',
    item_type: 'card',
    item_name: 'moment_of_the_day',
    position_in_unit: 1,
    target_content_type: 'url',
    target_content_id: !!url?.length ? url : headline,
  };

  const sponsorTrackingData = {
    ...baseTrackingData,
    item_type: 'text',
    item_name: 'presented_by',
  };

  const Thumbnail = () => {
    if (!images?.standard?.length) {
      return (
        <figure>
          <img src={ImagePlaceholder} alt={images?.standard_alt_text}/>
        </figure>
      );
    }
    return (
      <figure>
        <CdnImage src={images.standard} alt={images?.standard_alt_text} />
      </figure>
    );
  };

  return (
    <section id="moment-of-the-day" className={className}>
      <FeedCard className={styles.motd}>
        {!!url?.length ?
          (
            <>
              <FeedCard.Link url={url} trackingData={baseTrackingData} isTrackable={true} forceNewBrowserTab={forceNewBrowserTab}>
                <h3>{headline}</h3>
              </FeedCard.Link>
              {!!sponsor && <Sponsor data={sponsor} isTrackable={true} trackingData={sponsorTrackingData} showLogo={false} />}
              <FeedCard.Link url={url} trackingData={baseTrackingData} isTrackable={true} forceNewBrowserTab={forceNewBrowserTab}>
                <Thumbnail />
              </FeedCard.Link>
            </>
          ) : (
            <>
              <h3>{headline}</h3>
              {!!sponsor && <Sponsor data={sponsor} isTrackable={true} trackingData={sponsorTrackingData} showLogo={false} />}
              <Thumbnail />
            </>
          )
        }
        <FeedCard.Reactions
          className={styles.emojiReactions}
          contentId={Number(data.items?.[0].id)}
          contentType="content-object"
          data={contentReactions}
          isTrackable={true}
          trackingData={baseTrackingData}
        />
      </FeedCard>
    </section>
  );
};

MomentOfTheDay.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    items: PropTypes.array.isRequired,
  }).isRequired,
};

export default MomentOfTheDay;
